export const API_PULL_INTERVAL = 60000;
export const ONE_MINUTE_INTERVAL_MS = 60 * 1000;
export const FIFTEEN_MINUTE_INTERVAL_MS = 15 * 60 * 1000;

export const SQ_M_TO_HECTARES = 1 / 10000;

export const TERRAWARE_MOBILE_APP_IOS_APP_STORE_LINK = 'https://apps.apple.com/us/app/terraware/id1568369900';
export const TERRAWARE_MOBILE_APP_ANDROID_GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.terraformation.seedcollector';
export const ACCELERATOR_LINK = 'https://www.terraformation.com/accelerator';

export enum APP_PATHS {
  ACCELERATOR = '/accelerator',
  ACCELERATOR_APPLICATION = '/accelerator/applications/:applicationId',
  ACCELERATOR_APPLICATION_DELIVERABLE = '/accelerator/applications/:applicationId/deliverable/:deliverableId',
  ACCELERATOR_APPLICATION_MAP = '/accelerator/applications/:applicationId/map',
  ACCELERATOR_APPLICATIONS = '/accelerator/applications',
  ACCELERATOR_COHORTS = '/accelerator/cohorts',
  ACCELERATOR_COHORTS_EDIT = '/accelerator/cohorts/:cohortId/edit',
  ACCELERATOR_COHORTS_NEW = '/accelerator/cohorts/new',
  ACCELERATOR_COHORTS_VIEW = '/accelerator/cohorts/:cohortId',
  ACCELERATOR_DELIVERABLES = '/accelerator/deliverables',
  ACCELERATOR_DELIVERABLE_VIEW = '/accelerator/deliverables/:deliverableId/submissions/:projectId',
  ACCELERATOR_DOCUMENT_PRODUCER_DOCUMENT_NEW = '/accelerator/documents/new',
  ACCELERATOR_DOCUMENT_PRODUCER_DOCUMENT_PREVIEW = '/accelerator/documents/:documentId/preview',
  ACCELERATOR_DOCUMENT_PRODUCER_DOCUMENT_VIEW = '/accelerator/documents/:documentId',
  ACCELERATOR_DOCUMENT_PRODUCER_DOCUMENTS = '/accelerator/documents',
  ACCELERATOR_MODULES = '/accelerator/modules',
  ACCELERATOR_MODULE_CONTENT = '/accelerator/modules/:moduleId',
  ACCELERATOR_MODULE_EVENTS_EDIT = '/accelerator/modules/:moduleId/event/edit',
  ACCELERATOR_ORGANIZATIONS = 'accelerator/organizations',
  ACCELERATOR_OVERVIEW = '/accelerator/overview',
  ACCELERATOR_PARTICIPANTS = '/accelerator/participants',
  ACCELERATOR_PARTICIPANTS_EDIT = '/accelerator/participants/:participantId/edit',
  ACCELERATOR_PARTICIPANTS_NEW = '/accelerator/participants/new',
  ACCELERATOR_PARTICIPANTS_VIEW = '/accelerator/participants/:participantId',
  ACCELERATOR_PEOPLE = '/accelerator/people',
  ACCELERATOR_PERSON_NEW = '/accelerator/people/new',
  ACCELERATOR_PERSON = '/accelerator/people/:userId',
  ACCELERATOR_PERSON_EDIT = '/accelerator/people/:userId/edit',
  ACCELERATOR_PROJECT_EDIT = '/accelerator/projects/:projectId/edit',
  ACCELERATOR_PROJECT_VIEW = '/accelerator/projects/:projectId',
  ACCELERATOR_PROJECT_SCORES = '/accelerator/projects/:projectId/scores',
  ACCELERATOR_PROJECT_SCORES_EDIT = '/accelerator/projects/:projectId/scores/edit',
  ACCELERATOR_SPECIES = '/accelerator/deliverables/:deliverableId/submissions/:projectId/species/:participantProjectSpeciesId',
  ACCELERATOR_SPECIES_EDIT = '/accelerator/deliverables/:deliverableId/submissions/:projectId/species/:participantProjectSpeciesId/edit',
  ACCELERATOR_PROJECT_VOTES = '/accelerator/projects/:projectId/votes',
  ACCELERATOR_PROJECT_VOTES_EDIT = '/accelerator/projects/:projectId/votes/edit',
  ACCESSIONS = '/accessions',
  ACCESSIONS2_NEW = '/accessions/new',
  ACCESSIONS2_ITEM = '/accessions/:accessionId',
  ACCESSIONS2_EDIT = '/accessions/:accessionId/edit',
  APPLICATIONS = '/applications',
  APPLICATION_OVERVIEW = '/applications/:applicationId',
  APPLICATION_MAP = '/applications/:applicationId/map',
  APPLICATION_MAP_UPDATE = '/applications/:applicationId/map/update',
  APPLICATION_PRESCREEN = '/applications/:applicationId/prescreen',
  APPLICATION_PRESCREEN_RESULT = '/applications/:applicationId/prescreen/result',
  APPLICATION_REVIEW = '/applications/:applicationId/review',
  APPLICATION_SECTION = '/applications/:applicationId/sections/:sectionId',
  APPLICATION_SECTION_DELIVERABLE = '/applications/:applicationId/sections/:sectionId/deliverables/:deliverableId',
  APPLICATION_SECTION_DELIVERABLE_EDIT = '/applications/:applicationId/sections/:sectionId/deliverables/:deliverableId/edit',
  BATCH_WITHDRAW = '/batch/withdraw',
  CHECKIN = '/checkin',
  DELIVERABLES = '/deliverables',
  DELIVERABLE_EDIT = '/deliverables/:deliverableId/submissions/:projectId/edit',
  DELIVERABLE_VIEW = '/deliverables/:deliverableId/submissions/:projectId',
  ERROR = '/error',
  ERROR_MOBILE_NOT_SUPPORTED = '/error/mobile-not-supported',
  ERROR_FAILED_TO_FETCH_ORG_DATA = '/error/cannot-fetch-org-data',
  HELP_SUPPORT = '/help-support',
  HELP_SUPPORT_FORM = '/help-support/:requestType',
  HOME = '/home',
  NURSERIES = '/nurseries',
  NURSERIES_EDIT = '/nurseries/:nurseryId/edit',
  NURSERIES_NEW = '/nurseries/new',
  NURSERIES_VIEW = '/nurseries/:nurseryId',
  INVENTORY = '/inventory',
  INVENTORY_BATCH = '/inventory/batch/:batchId',
  INVENTORY_BATCH_FOR_NURSERY = '/inventory/nursery/:nurseryId/batch/:batchId',
  INVENTORY_BATCH_FOR_SPECIES = '/inventory/species/:speciesId/batch/:batchId',
  INVENTORY_NEW = '/inventory/new',
  INVENTORY_ITEM_FOR_NURSERY = '/inventory/nursery/:nurseryId',
  INVENTORY_ITEM_FOR_SPECIES = '/inventory/:speciesId',
  INVENTORY_WITHDRAW = '/inventory/withdraw',
  OBSERVATIONS = '/observations',
  SCHEDULE_OBSERVATION = '/observations/schedule',
  RESCHEDULE_OBSERVATION = '/observations/schedule/:observationId',
  OBSERVATIONS_SITE = '/observations/:plantingSiteId',
  OBSERVATION_DETAILS = '/observations/:plantingSiteId/results/:observationId',
  OBSERVATION_PLANTING_ZONE_DETAILS = '/observations/:plantingSiteId/results/:observationId/zone/:plantingZoneId',
  OBSERVATION_MONITORING_PLOT_DETAILS = '/observations/:plantingSiteId/results/:observationId/zone/:plantingZoneId/plot/:monitoringPlotId',
  OPT_IN = '/opt-in',
  ORGANIZATION_EDIT = '/organization/edit',
  ORGANIZATION = '/organization',
  PEOPLE_NEW = '/people/new',
  PEOPLE_EDIT = '/people/:personId/edit',
  PEOPLE_VIEW = '/people/:personId',
  PEOPLE = '/people',
  PLANTS_DASHBOARD = '/plants/dashboard',
  PLANTING_SITE_DASHBOARD = '/plants/dashboard/:plantingSiteId',
  PROJECT_EDIT = '/projects/:projectId/edit',
  PROJECT_MODULE = '/projects/:projectId/modules/:moduleId',
  PROJECT_MODULE_ADDITIONAL_RESOURCES = '/projects/:projectId/modules/:moduleId/additionalResources',
  PROJECT_MODULE_PREPARATION_MATERIALS = '/projects/:projectId/modules/:moduleId/preparationMaterials',
  PROJECT_MODULE_SESSION = '/projects/:projectId/modules/:moduleId/sessions/:sessionId',
  PROJECT_MODULES = '/projects/:projectId/modules',
  PROJECT_VIEW = '/projects/:projectId',
  PROJECTS = '/projects',
  PROJECTS_NEW = '/projects/new',
  REPORTS = '/reports',
  REPORTS_EDIT = '/reports/:reportId/edit',
  REPORTS_SETTINGS = '/reports/settings',
  REPORTS_SETTINGS_EDIT = '/reports/settings/edit',
  REPORTS_VIEW = '/reports/:reportId',
  SEED_BANKS = '/seedbanks',
  SEED_BANKS_EDIT = '/seedbanks/:seedBankId/edit',
  SEED_BANKS_NEW = '/seedbanks/new',
  SEED_BANKS_VIEW = '/seedbanks/:seedBankId',
  SEEDS_DASHBOARD = '/seeds-dashboard',
  SPECIES = '/species',
  SPECIES_DETAILS = '/species/:speciesId',
  SPECIES_EDIT = '/species/:speciesId/edit',
  SPECIES_NEW = '/species/new',
  WELCOME = '/welcome',
  MY_ACCOUNT = '/myaccount',
  MY_ACCOUNT_EDIT = '/myaccount/edit',
  PLANTING_SITES = '/planting-sites',
  PLANTING_SITES_NEW = '/planting-sites/new',
  PLANTING_SITES_DRAFT_NEW = '/planting-sites/draft/new',
  PLANTING_SITES_DRAFT_VIEW = '/planting-sites/draft/:plantingSiteId',
  PLANTING_SITES_DRAFT_EDIT = '/planting-sites/draft/:plantingSiteId/edit',
  PLANTING_SITES_DRAFT_ZONE_VIEW = '/planting-sites/draft/:plantingSiteId/zone/:zoneId',
  PLANTING_SITES_VIEW = '/planting-sites/:plantingSiteId',
  PLANTING_SITES_EDIT = '/planting-sites/:plantingSiteId/edit',
  PLANTING_SITES_ZONE_VIEW = '/planting-sites/:plantingSiteId/zone/:zoneId',
  PLANTING_SITES_SUBZONE_VIEW = '/planting-sites/:plantingSiteId/zone/:zoneId/subzone/:subzoneId',
  NURSERY_WITHDRAWALS = '/nursery/withdrawals',
  NURSERY_WITHDRAWALS_DETAILS = '/nursery/withdrawals/:withdrawalId',
  NURSERY_REASSIGNMENT = '/nursery/reassignment/:deliveryId',
  ADMIN_COHORT_DELIVERABLE = '/admin/cohorts/:cohortId/deliverables/:deliverableId',
}
